<template>
  <div id="content">
    <div class="vx-row">
      
    </div>
  </div>
</template>

<script>

export default {
  components: { },
  data() {
    return {
      
    };
  },
  methods: {
    loadData() {
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
</style>
